<template>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {
      parentUrl: '/parametrage',
      links: [
        {
          name: 'actualité',
          path: 'actualite',
          componentName: 'actualite',
        },
        {
          name: 'Gestion CGU',
          path: 'CGU',
          componentName: 'CGU',
        },
        {
          name: 'Message et notifications',
          path: 'messages-platefrome-promoteurs-list',
          componentName: 'messages-platefrome-promoteurs-list',
        },
        {
          name: 'Logiciels',
          path: 'softwares',
          componentName: 'softwares',
        },
      ],
    }
  },
  created() {
    this.$store.commit('subSidebar/LINKS', this.links)
    this.$store.commit('subSidebar/PARENT_URL', this.parentUrl)
  },
  mounted() {
    this.$store.commit('sidebar/RESIZE_WINDOW', true)
  },
  destroyed() {
    this.$store.commit('sidebar/RESIZE_WINDOW', false)
  },
}
</script>

<style></style>
